import React from "react";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";

const Modal = ({ setIsOpen }) => {
  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h2 className={styles.heading}>Nossa <strong>Política de Privacidade</strong></h2>
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className={styles.modalContent}>
			<div class="row align-items-center Mb20m">
				<div class="col-12 col-lg-2 text-left text-lg-right">
					<a href="https://www.megaconsultas.com.br/v2/imagens/v2/nossa-politica.pdf" target="_blank"><i class="fas fa-print"></i> IMPRIMIR</a>
				</div>
			</div>
			<div class="row Mb80">
			<div class="col-12">
				<p>Marcelo Fernando Guedes ME / CNPJ: 09.275.651/0001-69<br/>
				Endereço: Rua Carijós, 862, Vila Alzira, Santo André, São Paulo<br/>
				Email: contato@megaconsultas.com.br / Telefone: 11 2228-1183</p>
				<p>Respeita a privacidade de cada visitante que acessa seus serviços.</p>
				<p>Nossa política de privacidade visa assegurar a garantia de que, quaisquer informações relativas aos usuários, não serão fornecidas, publicadas ou comercializadas em quaisquer circunstâncias.</p>
				<p>A Mega Consultas obtém informações dos usuários através de Cadastro e Cookies.</p>
				<p>Cadastro</p>
				<p>Para usufruir dos serviços oferecidos em nosso site, você precisa se cadastrar e aceitar as condições de uso do sistema. Este cadastro é armazenado em um banco de dados protegido e sigiloso. Seu e-mail e dados pessoais não serão divulgados em hipótese alguma.</p>
				<p>Utilizaremos seu e-mail apenas para envio de mensagens automáticas geradas pelo sistema e boletins periódicos.</p>
				<p>O usuário cadastrado na Mega Consultas estará habilitado a rever ou alterar seu cadastro na opção (meus dados) no menu do painel do cliente.</p>
				<p>Não Divulgação das Informações</p>
				<p>A Mega Consultas compromete-se a não comerializar, compartilhar ou distribuir dados pessoais de usuários, tais como Nome, CPF, CNPJ, endereço eletrônico ou endereço postal a terceiros, entidades, empresas, ou sites.</p>
				<p>A Mega Consultas coleta informações através de cookies (informações enviadas pelo servidor do Mega Consultas ao computador do usuário, para identificá-lo).</p>
				<p>Os cookies servem unicamente para controle interno de audiência e de navegação e jamais para controlar, identificar ou rastrear preferências do internauta, exceto quando este desrespeitar alguma regra de segurança ou exercer alguma atividade prejudicial ao bom funcionamento do site, como por exemplo tentativas de hackear o serviço.</p>
				<p>A aceitação dos cookies pode ser livremente alterada na configuração de seu navegador.</p>
				<p>Direito de Acesso</p>
				<p>O usuário tem direito de acessar e atualizar seus dados pessoais ou solicitar sua exclusão.</p>
				<p>Caso o usuário deseje excluir seus dados pessoais em poder da Mega Consultas, deverá entrar em contato com nossa equipe através do fale conosco, do email: contato@megaconsultas.com.br ou do telefone (11) 2228-1183 e pedir o devido cancelamento.</p>
				<p>A solicitação do usuário será tratada de maneira imediata e adequada.</p>
				<p>Não será cobrada qualquer taxa para executar a exclusão.</p>
				<p>Segurança das Informações</p>
				<p>Todos os dados pessoais informados ao nosso site são armazenados em um banco de dados reservado e com acesso restrito a algumas pessoas habilitadas que são obrigadas, por contrato, a manter a confidencialidade das informações e não utilizá-las inadequadamente.</p>
				<p>Nossa política envolve os seguintes dispositivos de segurança:</p>
				<p>1) Certificado de Segurança HTTPS: trata-se da mesma certificação utilizada em home banking e garante que todas as informações do site são trafegadas na Internet de forma criptografada;</p>
				<p>2) Acesso por meio de senha única e exclusiva, onde nem mesmo os atendentes da empresa têm acesso a senha;</p>
				<p>3) Senha armazenada em banco de dados de maneira criptografada.</p>
				<p>Se você tiver alguma dúvida sobre esta Política de Privacidade sinta-se a vontade para entrar em contato.</p>
				<p>MEGA CONSULTAS<br/>
				Email: contato@megaconsultas.com.br / Telefone: 11 2228-1183<br/>
				Marcelo Fernando Guedes ME / CNPJ: 09.275.651/0001-69<br/>
				Rua Carijós, 862, Vila Alzira, Santo André, São Paulo</p>
			</div>
		</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
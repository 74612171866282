import './index.css';
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
//import { useGeolocated } from "react-geolocated";
import {isMobile} from 'react-device-detect';
//import axios from 'axios';
import Modal from '../modalMega';
import Termos from '../modalMega/termos';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function Layout() {
    const [openCookie, setOpenCookie] = useState(true);
    const [deviceId, setDeviceId] = useState(null);
    const [userIp, setUserIp] = useState(null);
    const [url, setUrl] = useState(window.location.href)
    const [vendor, setVendor] = useState(navigator?.vendor)
    const [platform, setPlatform] = useState(navigator?.platform)
    const [appVersion, setAppVersion] = useState(navigator?.appVersion)
    const [userPlatform, setUserPlatform] = useState(navigator?.userAgent)
    const [mobile, setMobile] = useState(isMobile)

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenTermos, setIsOpenTermos] = useState(false);

    const coords = null;

    //const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    //    positionOptions: {
    //        enableHighAccuracy: false,
    //    },
    //    userDecisionTimeout: 10000,
    //});

    function getIp(callback) {
        function response(s)
        {
            callback(window.userip);
    
            s.onload = s.onerror = null;
            document.body.removeChild(s);
        }
    
        function trigger()
        {
            window.userip = false;
    
            var s = document.createElement("script");
            s.async = true;
            s.onload = function() {
                response(s);
            };
            s.onerror = function() {
                response(s);
            };
    
            s.src = "https://l2.io/ip.js?var=userip";
            document.body.appendChild(s);
        }
    
        if (/^(interactive|complete)$/i.test(document.readyState)) {
            trigger();
        } else {
            document.addEventListener('DOMContentLoaded', trigger);
        }
    }

    const handleAccept = () => {
        let device = localStorage.getItem("device_id")
        localStorage.setItem(device, 'true')
        setOpenCookie(false)

        const dataBody = {
            userIp: userIp,
            host: url,
            deviceId: deviceId,
            vendor: vendor,
            platform: platform,
            appVersion: appVersion,
            userPlatform: userPlatform,
            mobile: mobile,
            latitude: coords?.latitude,
            longitude: coords?.longitude,
        }

        fetch('https://www.megaconsultas.com.br/v2/cookie', {
            method: "POST",
            body: JSON.stringify(dataBody),
            headers: {"Content-type": "application/json; charset=UTF-8"}
        })
        .then(response => response.json()) 
        .then(json => console.log(json))
        .catch(err => console.log(err));
    }

    const handleCancel = () => {
        let device = localStorage.getItem("device_id")
        localStorage.setItem(device, 'false')
        setOpenCookie(false)
    }


    useEffect(()=>{
        getIp(setUserIp)

        let device = localStorage.getItem("device_id")

        if(!device) {
            localStorage.setItem("device_id", uuid())
            device = localStorage.getItem("device_id")
        } else {
            if (localStorage.getItem(device) === 'true') {
                setOpenCookie(false)
            }
        }
        console.log(navigator)
        setDeviceId(device)
    })

    return (
        <div>
        <div className="cookie-box" style={openCookie ? {} : {display: 'none'}}>
            <div className="cookie-row">
                <h3 className="cookie-title">Controle sua privacidade</h3>
                <div className="cookie-text">Nosso site usa cookies para melhorar a navegação.</div>
            </div>
            <div className="cookie-row">
                <div className="cookie-links">
                    <a 
                    onClick={() => setIsOpen(true)}
                    href="#">Política de Privacidade</a> - <a onClick={() => setIsOpenTermos(true)} href="#">Termos de uso</a>
                </div>
            </div>
            <div className="cookie-row cookie-row-buttons">
                <div className="cookie-button">
                    <button 
                        className="button-cancel"
                        onClick={()=> handleCancel()}
                        >Rejeitar</button>
                </div>
                <div className="cookie-button">
                    <button 
                        className="button-accept"
                        onClick={()=> handleAccept()}
                        >Aceitar</button>
                </div>
            </div>
        </div>
                
            {isOpen && <Modal setIsOpen={setIsOpen} />}
            {isOpenTermos && <Termos setIsOpen={setIsOpenTermos} />}
    </div>
    );
}

export default Layout;
